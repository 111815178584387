import * as Types from './types.generated';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetInitialDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetInitialDataQuery = { __typename?: 'Query', getUser?: { __typename?: 'User', id: string, email?: string | null, password?: boolean | null, role: { __typename?: 'UserRole', id: string, label: string } } | null };

export const GetInitialDataDocument = gql`
    query getInitialData {
  getUser {
    id
    email
    role {
      id
      label
    }
    password
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetInitialDataGQL extends Apollo.Query<GetInitialDataQuery, GetInitialDataQueryVariables> {
    document = GetInitialDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }