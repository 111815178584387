import {NgModule} from '@angular/core';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatRippleModule} from '@angular/material/core';
import {MatListModule} from '@angular/material/list';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatTableModule} from '@angular/material/table';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import {MatBadgeModule} from '@angular/material/badge';
import {MatChipsModule} from '@angular/material/chips';

@NgModule({
	declarations:[],
	imports:[
		MatToolbarModule,
		MatIconModule,
		MatMenuModule,
		MatButtonModule,
		MatRippleModule,
		MatListModule,
		MatTooltipModule,
		MatSidenavModule,
		MatCardModule,
		MatFormFieldModule,
		MatInputModule,
		MatGridListModule,
		MatTableModule,
		MatProgressBarModule,
		MatDialogModule,
		MatSlideToggleModule,
		MatSelectModule,
		MatBadgeModule,
		MatChipsModule
	],
	exports:[
		MatToolbarModule,
		MatIconModule,
		MatMenuModule,
		MatButtonModule,
		MatRippleModule,
		MatListModule,
		MatTooltipModule,
		MatSidenavModule,
		MatCardModule,
		MatFormFieldModule,
		MatInputModule,
		MatGridListModule,
		MatTableModule,
		MatProgressBarModule,
		MatDialogModule,
		MatSlideToggleModule,
		MatSelectModule,
		MatBadgeModule,
		MatChipsModule
	],
	providers:[]
})
export class CustomMaterialModule{
}
