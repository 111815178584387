import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomMaterialModule} from './custom-material.module';
import {RouterModule} from '@angular/router';
import {NgOptimizedImage} from '@angular/common';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import {PhoneNumberUtilProvider} from './phone-number-util.provider';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {ZXingScannerModule} from '@zxing/ngx-scanner';
import {NgxFileDropModule} from 'ngx-file-drop';

@NgModule({
	imports:[
		RouterModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		CustomMaterialModule,
		NgOptimizedImage,
		CKEditorModule,
		ZXingScannerModule,
		NgxFileDropModule
	],
	exports:[
		RouterModule,
		CommonModule,
		CustomMaterialModule,
		FormsModule,
		ReactiveFormsModule,
		NgOptimizedImage,
		CKEditorModule,
		ZXingScannerModule,
		NgxFileDropModule
	],
	providers:[
		PhoneNumberUtilProvider
	]
})
export class SharedModule{
}
